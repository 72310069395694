import type { Language } from '@wix/thunderbolt-becky-types';
import type { ThunderboltLegacySupportedLanguage } from './constants';
import { thunderboltLegacySupportedLanguages } from './constants';
import type {
  GoogleMapData,
  GoogleMapLanguage,
  GoogleMapProperties,
} from './GoogleMap.types';

export const calculateGoogleMapLanguage = (
  languageFromData: GoogleMapData['language'] | undefined,
  languageFromProperties: GoogleMapProperties['language'],
  siteLanguage: Language,
): GoogleMapLanguage => {
  if (languageFromData) {
    return languageFromData;
  }

  const preferredLanguage =
    languageFromProperties === 'userLang'
      ? siteLanguage
      : languageFromProperties;

  return getSupportedLanguage(preferredLanguage);
};

export const getSupportedLanguage = (language: string) => {
  return thunderboltLegacySupportedLanguages.includes(
    language as ThunderboltLegacySupportedLanguage,
  )
    ? (language as ThunderboltLegacySupportedLanguage)
    : 'en';
};

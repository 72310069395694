export const documentManagementLegacySupportedLanguages = [
  'userLang',
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fr',
  'hi',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'zh',
  'th',
  'uk',
  'tr',
] as const;

export type DocumentManagementLegacySupportedLanguages =
  (typeof documentManagementLegacySupportedLanguages)[number];

export const thunderboltLegacySupportedLanguages = [
  'pt-br',
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
] as const;

export type ThunderboltLegacySupportedLanguage =
  (typeof thunderboltLegacySupportedLanguages)[number];

export const supportedLanguages = [
  'af',
  'sq',
  'am',
  'ar',
  'hy',
  'az',
  'eu',
  'be',
  'bn',
  'bs',
  'bg',
  'my',
  'ca',
  'zh',
  'zh-CN',
  'zh-HK',
  'zh-TW',
  'hr',
  'cs',
  'da',
  'nl',
  'en',
  'en-AU',
  'en-GB',
  'et',
  'fa',
  'fi',
  'fil',
  'fr',
  'fr-CA',
  'gl',
  'ka',
  'de',
  'el',
  'gu',
  'iw',
  'hi',
  'hu',
  'is',
  'id',
  'it',
  'ja',
  'kn',
  'kk',
  'km',
  'ko',
  'ky',
  'lo',
  'lv',
  'lt',
  'mk',
  'ms',
  'ml',
  'mr',
  'mn',
  'ne',
  'no',
  'pl',
  'pt',
  'pt-BR',
  'pt-PT',
  'pa',
  'ro',
  'ru',
  'sr',
  'si',
  'sk',
  'sl',
  'es',
  'es-419',
  'sw',
  'sv',
  'ta',
  'te',
  'th',
  'tr',
  'uk',
  'ur',
  'uz',
  'vi',
  'zu',
] as const;

export type SupportedLanguage = (typeof supportedLanguages)[number];

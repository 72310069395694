import { withCompController } from '@wix/editor-elements-integrations';
import { mapConsentPolicyStateRefValues } from '@wix/thunderbolt-elements/src/components/ConsentPolicyWrapper/viewer/utils';
import type {
  GoogleMapMapperProps,
  GoogleMapProps,
  GoogleMapStateRefs,
  GoogleMapControllerProps,
} from '../GoogleMap.types';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import { calculateGoogleMapLanguage, getSupportedLanguage } from '../utils';
import { languageExperimentSpec } from '../constants';

export const innerControllerFunction = ({
  stateValues,
  mapperProps,
}: {
  stateValues: GoogleMapStateRefs;
  mapperProps: GoogleMapMapperProps;
}) => {
  const { dataLanguage, propsLanguage, siteLanguage, urlQueries } = mapperProps;

  /**
   * This logic should be in GoogleMap.mapper.ts.
   * Should move it there when the spec will be fully opened.
   */
  let language;
  if (isExperimentOpen(stateValues.experiments, languageExperimentSpec)) {
    language = getSupportedLanguage(siteLanguage);
  } else {
    language = calculateGoogleMapLanguage(
      dataLanguage,
      propsLanguage,
      siteLanguage,
    );
  }

  const wrappedUrlQueries = new URLSearchParams(urlQueries);
  wrappedUrlQueries.set('language', language);

  return {
    ...mapperProps,
    language,
    urlQueries: wrappedUrlQueries.toString(),
    ...mapConsentPolicyStateRefValues(stateValues),
  };
};

export default withCompController<
  GoogleMapMapperProps,
  GoogleMapControllerProps,
  GoogleMapProps,
  GoogleMapStateRefs
>(innerControllerFunction);
